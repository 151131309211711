import Masuk from "./Masuk"

const HalamanMasuk = () => {
  return (
    <div>
      <Masuk />
    </div>
  )
}

export default HalamanMasuk