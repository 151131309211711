import EditProfileGoogle from "./editProfileGoogle"

const editProfileGooglePage = () => {
  return (
    <div>
        <EditProfileGoogle/>
    </div>
  )
}

export default editProfileGooglePage