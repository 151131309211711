import Tac from "./syaratketentuan"
import Footer from "../../components/Footer"
import Navbar from "../../components/Header/Navbar"

const HalamanTAC = () => {
  return (
    <div>
      <Navbar />
      <Tac />
      <Footer />
    </div>
  )
}

export default HalamanTAC