import Footer from "../../components/Footer"
import Utakmain from "./Utakmain"
import Navbar from "../../components/Header/Navbar"
import Glosarium from "../../components/Glosarium"

const Utakmainpage = () => {
  return (
    <div>
      <Navbar />
      <Utakmain />
      <Footer />
      {/* <Glosarium/> */}
    </div>
  )
}

export default Utakmainpage